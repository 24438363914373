export const SNOWPACK_PUBLIC_ENVIRONMENT = "development";
export const SNOWPACK_PUBLIC_API_URL = "https://pim-dev.crystallize.digital";
export const SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API = "https://dev-subscription-service.crystallize.com/api/graphql";
export const SNOWPACK_PUBLIC_PIM_VERSION = "e224121b5a06e52976bb8283a77f417f35e8b281";
export const SNOWPACK_PUBLIC_GOOGLE_CLOUD_API = "AIzaSyDpkkRla7AV5DLBhFrnAevJNGJLMDVZpA8";
export const SNOWPACK_PUBLIC_CLIENT_CATALOGUE_URL = "https://api-dev.crystallize.digital/:tenantIdentifier/catalogue";
export const SNOWPACK_PUBLIC_STRIPE_PUBLISHABLE_KEY = "pk_test_51IyI2yJ2kHvdWVjoxFoIeCBpgKTlBapKkwJloJSstFMJEqmjLTQc1uFpoYWaasK7SqJmLBIWY0IEP1NoUR1SQzgl00edViJtLj";
export const SNOWPACK_PUBLIC_CLIENT_SEARCH_URL = "https://api-dev.crystallize.digital/:tenantIdentifier/search";
export const MODE = "development";
export const NODE_ENV = "development";
export const SSR = false;